<template>
    <div class="">
        
      <section id="logos" class="bg_light">
        <div class="container partner2 padding">

          <div class="row">
            <div class="col-sm-12" >

              <h2  v-if="pgLoading" class="uppercase webkit-animation webkit-25per-35 mb-5"></h2>
              <p v-if="pgLoading" class="heading_space webkit-animation webkit-50per-25"></p>

              <h2 v-if="!pgLoading" 
                  :class="($i18n.locale == 'ar') ? 'hacen_algeria' : 'uppercase'"
                  :style="($i18n.locale == 'ar') ? 'text-align:right;' : ''"
                  v-html="($i18n.locale == 'ar') ? setting.title_ar : setting.title_en">
              </h2>
              <p  v-if="!pgLoading" 
              :style="($i18n.locale == 'ar') ? 'text-align:right;' : ''"
                  :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                  v-html="($i18n.locale == 'ar') ? setting.body_ar : setting.body_en" 
                  class="heading_space">
              </p>

            </div>
          </div>
          
          <div id="partner-slider" class="row">
            <div class="col-md-12">
                
              <div v-if="!pgLoading" id="full-slider">
                <div class="owl-controls clickable hidden-xs">
                  <div class="owl-buttons">
                    <div @click="prev()" 
                        id="swiperBtnPrev" 
                        class="owl-prev">
                        <span class="fa fa-angle-left"></span>
                    </div>
                    <div @click="next()" 
                        id="swiperBtnNext" 
                        class="owl-next">
                        <span class="fa fa-angle-right"></span>
                    </div>
                  </div>
                </div>
              </div>

                <Swiper v-if="pgLoading" ref="mySwiper" :options="swiperOptions">
                  <SwiperSlide v-for="(item, index) in 5" :key="index">
                    <div class="item">
                      <div class="webkit-animation webkit-218-130"></div>
                    </div>
                  </SwiperSlide>
                </Swiper>

                <Swiper v-if="!pgLoading" ref="mySwiper" :options="swiperOptions">
                  <SwiperSlide v-for="(item, index) in items" :key="index">
                    <a :href="(item.redirect) ? item.redirect: 'javascript:;'" 
                       :target="(item.redirect) ? '_blank' : ''">
                      <div class="item">
                          <img 
                            v-lazy="(item.image) ? item.image.url : ''" 
                            :src="(item.image) ? item.image.url : ''" 
                            :alt="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                      </div>
                    </a>
                  </SwiperSlide>
                </Swiper>

            </div>
          </div>

      </div>
    </section>

    </div>
</template>


<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        name: 'OurPartner',
        components: {
          Swiper,
          SwiperSlide
        },
        directives: {
          swiper: directive
        },
        data(){
            return {
              pgLoading: true,

              setting: '',
              items: [],
              
              swiperOptions: {
                name: 'mySwiper',
                loop: true,
                speed: 700,
                autoplay: true,
                spaceBetween: 30,
                slidesPerView: 5,
                navigation: {
                    nextEl: '#swiperBtnNext',
                    prevEl: '#swiperBtnPrev'
                },
                breakpoints: {
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                }
              },
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            },
          }
        },
        computed: {
          swiper() {
            return this.$refs.mySwiper.$swiper
          }
        },
        mounted() {},
        created() {
          //
          if(localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
          }

          this.fetchData();
        },
        methods: {
          //
          fetchData() {
              this.pgLoading = true;
              this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
              };
              const options = {
                  url: window.baseURL+'/partners',
                  method: 'GET',
                  data: {},
                  params: {}
              }
              this.axios(options)
                .then(res => {
                  this.pgLoading = false;
                  this.setting = res.data.setting;
                  this.items = res.data.items;
                })
                .catch(() => {})
                .finally(() => {});
          },

          prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
          },
          next() {
            this.$refs.mySwiper.$swiper.slideNext();
          },

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
